import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/Send";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import * as wss from "../../../utils/wss";
import { useTranslation } from "react-i18next";

const getDirectChatHistory = (directChatHistory, socketId = null) => {
  console.log("directChatHistory>>>", directChatHistory);
  console.log("socketId>>>", socketId);
  if (!socketId || !directChatHistory) {
    return [];
  }

  const history = directChatHistory.find((h) => h.socketId === socketId);

  return history ? history.chatHistory : [];
};

const DirectChat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const scrollRef = useRef();

  const { identity, activeConversation, directChatHistory } = useSelector(
    (state) => state.app
  );
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");

  const sendMessage = () => {
    if (messageInput.length > 0) {
      wss.sendDirectMessage({
        receiverSocketId: activeConversation.socketId,
        identity: identity,
        messageContent: messageInput,
      });

      setMessageInput("");
    }
  };

  const handleKeyPressed = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleTextChange = (event) => {
    setMessageInput(event.target.value);
  };

  useEffect(() => {
    setMessages(
      getDirectChatHistory(
        directChatHistory,
        activeConversation ? activeConversation.socketId : null
      )
    );
  }, [activeConversation, directChatHistory]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Box
      sx={{
        p: 2,
        width: 1,
        height: "50vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="subtitle1"
        color={activeConversation ? "secondary" : ""}
      >
        {activeConversation
          ? `${t("DIRECT CHAT")}: ${activeConversation.identity}`
          : t("DIRECT CHAT")}
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          //   p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack spacing={1} sx={{ flex: 1, overflowY: "auto" }}>
          {messages.map((message, index) => {
            const isLastMessage = index === messages.length - 1;
            return (
              <Box
                ref={isLastMessage ? scrollRef : null}
                key={`${message.content}${index}`}
                sx={{
                  px: 2,
                  py: 1,
                  borderRadius: 2,
                  backgroundColor: message.isAuthor
                    ? theme.palette.grey[500]
                    : theme.palette.primary.light,
                  alignSelf: message.isAuthor ? "flex-end" : "flex-start",
                }}
              >
                <Typography variant="body2">
                  <strong>{message.identity}</strong>: {message.messageContent}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Box>

      {/* Input field fixed at the bottom */}
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          pt: 2,
        }}
      >
        <TextField
          color="secondary"
          size="small"
          fullWidth
          placeholder={t("Type a message")}
          value={messageInput}
          onChange={handleTextChange}
          onKeyDown={handleKeyPressed}
        />
        <IconButton onClick={sendMessage}>
          <SendOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DirectChat;
