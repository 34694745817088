import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const Intro = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();

  const pushToJoinRoomPage = () => {
    navigate("/join-room");
  };

  const pushToJoinRoomPageAsHost = () => {
    navigate("/join-room?host=true");
  };

  const pushToChatPage = () => {
    if (location.state?.from === "/chat") {
      navigate("/chat");
    } else {
      navigate("/");
    }
  };

  return (
    <Container>
      <Box
        sx={{
          width: 1,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            px: 4,
            py: 8,
            width: 400,
            rowGap: 6,
          }}
        >
          <Typography variant="h3" textAlign="center">
            {t("Hangout")}
          </Typography>
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={pushToJoinRoomPageAsHost}
            >
              {t("Host meeting")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={pushToJoinRoomPage}
            >
              {t("Join meeting")}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={pushToChatPage}
            >
              {t("Go Back")}
            </Button>
          </Stack>
        </Card>
      </Box>
    </Container>
  );
};

export default Intro;
