import axios from "axios";

export const ChatService = {
  async getRoomExist(roomId) {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_SERVICES}/api/room-exists/${roomId}`
    );

    return response.data;
  },

  async getTURNCredentials() {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL_SERVICES}/api/get-turn-credentials`
    );

    return response.data;
  },
};
