import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import { io } from "socket.io-client";
import Peer, { SignalData } from "simple-peer";
import axios from "axios";
import { MessageService } from "../api/message.service";

export const ChatContext = createContext();

export const useChatContext = () => {
  return useContext(ChatContext);
};

export const ChatProvider = ({ children }) => {
  const { userInfo } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [filtred, setFilteredData] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [markAsRead, setMarkAsRead] = useState([]);
  //socket
  const [socket, setSocket] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [playSound, setPlaySound] = useState(true);
 
  //videochat
  const [usersInRoom, setUsersInRoom] = useState([]);

  //old
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [ongoingCall, setOngoingCall] = useState(null);
  const [peer, setPeer] = useState(null);
  const [isCallEnded, setIsCallEnded] = useState(false);

  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();

  // console.log("onlineUsersApp:", onlineUsers);
  
  //==========calling==================
  const currentSocketUser = onlineUsers?.find(
    (onlineUser) => onlineUser.userId === userInfo?.user.id
  );

  const getMediaStream = useCallback(
    async (faceMode) => {
      if (localStream) {
        return localStream;
      }

      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 360, ideal: 720, max: 1080 },
            frameRate: { min: 16, ideal: 30, max: 30 },
            facingMode: videoDevices.length > 0 ? faceMode : undefined,
          },
        });
        setLocalStream(stream);
        return stream;
      } catch (error) {
        console.log("Failed to get the stream", error);
        setLocalStream(null);
        return null;
      }
    },
    [localStream]
  );

  const handleCall = useCallback(
    async (user) => {
      setIsCallEnded(false);

      if (!currentSocketUser || !socket) return;

      const stream = await getMediaStream();

      if (!stream) {
        console.log("No stream in handleCall");
        return;
      }

      const participants = { caller: currentSocketUser, receiver: user };

      setOngoingCall({
        participants,
        isRinging: false,
      });
      socket.emit("call", participants);
    },
    [socket, currentSocketUser, ongoingCall]
  );

  const onIncomingCall = useCallback(
    (participants) => {
      setOngoingCall({
        participants,
        isRinging: true,
      });
    },
    [socket, currentSocketUser, ongoingCall]
  );

  
  const handleHangup = useCallback(
    (data) => {
      if (socket && userInfo && data?.ongoingCall && data?.isEmitHangup) {
        socket.emit("hangup", {
          ongoingCall: data.ongoingCall,
          userHangingupId: userInfo?.id,
        });
      }

      setOngoingCall(null);
      setPeer(null);

      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
        setLocalStream(null);
      }

      setIsCallEnded(true);
    },
    [socket, userInfo, localStream]
  );

  const createPeer = useCallback(
    (stream, initiator) => {
      const iceServers = [
        {
          urls: [
            "stun:stun.1.google.com:19302",
            "stun:stun1.1.google.com:19302",
            "stun:stun2.1.google.com:19302",
            "stun:stun3.1.google.com:19302",
          ],
        },
      ];

      const peer = new Peer({
        stream,
        initiator,
        trickle: true,
        config: { iceServers },
      });

      peer.on("stream", (stream) => {
        setPeer((prevPeer) => {
          if (prevPeer) {
            return { ...prevPeer, stream };
          }
          return prevPeer;
        });
      });

      peer.on("error", console.error);
      peer.on("close", () => handleHangup({}));

      const rtcPeerConnection = peer._pc;

      rtcPeerConnection.oniceconnectionstatechange = async () => {
        if (
          rtcPeerConnection.iceConnectionState === "disconnected" ||
          rtcPeerConnection.iceConnectionState === "failed"
        ) {
          handleHangup({});
        }
      };

      return peer;
    },
    [ongoingCall, setPeer]
  );

  const completePeerConnection = useCallback(
    async (connectionData) => {
      if (!localStream) {
        console.log("Missing the localStream");
        return;
      }

      if (peer) {
        peer.peerConnection?.signal(connectionData.sdp);
        return;
      }

      const newPeer = createPeer(localStream, true);

      setPeer({
        peerConnection: newPeer,
        partipantUser: connectionData.ongoingCall.participants.receiver,
        stream: undefined,
      });

      newPeer.on("signal", async (data) => {
        if (socket) {
          socket.emit("webrtcSignal", {
            sdp: data,
            ongoingCall,
            isCaller: true,
          });
        }
      });
    },
    [localStream, createPeer, peer, ongoingCall]
  );

  const handleJoinCall = useCallback(
    async (ongoingCall) => {
      setIsCallEnded(false);

      setOngoingCall((prev) => {
        if (prev) {
          return { ...prev, isRinging: false };
        }
        return prev;
      });

      const stream = await getMediaStream();
      if (!stream) {
        console.log("Could not get stream in handleJoinCall");
        handleHangup({
          ongoingCall: ongoingCall ? ongoingCall : undefined,
          isEmitHangup: true,
        })
        return;
      }

      const newPeer = createPeer(stream, true);

      setPeer({
        peerConnection: newPeer,
        partipantUser: ongoingCall.participants.caller,
        stream: undefined,
      });

      newPeer.on("signal", async (data) => {

        if (newPeer.destroyed) {
          console.warn("Cannot signal, peer is destroyed.");
          return;
        }

        if (socket) {
          socket.emit("webrtcSignal", {
            sdp: data,
            ongoingCall,
            isCaller: false,
          });
        }
      });
    },
    [socket, currentSocketUser]
  );

  //==========message==================
  const updateMessages = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${selectedConversation?.id}&populate=*`
      );
      const res = response.data.data;
      // console.log("updateMessages", res);
      setMessages(res);
    } catch (error) {
      console.error(error.message);
    }
  }, [selectedConversation]);

  useEffect(() => {
    const getMessages = async () => {
      if (!selectedConversation?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${selectedConversation?.id}&populate=*`
        );
        const res = response.data.data;
        // console.log("dataMessages", res);
        setMessages(res);
      } catch (error) {
        console.error(error.message);
      }
    };

    getMessages();
  }, [selectedConversation, setMessages]);

  //==========conversation==================
  const updateConversations = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_STRAPI_URL}/api/conversations?filters[members][id]=${userInfo?.user.id}&populate=*`
      );
      const res = response.data.data;

      // Сортировка разговоров по дате последнего сообщения или дате создания
      const sortedConversations = res.sort((a, b) => {
        const aMessages = a.attributes.messages.data;
        const bMessages = b.attributes.messages.data;

        // Если у разговоров есть сообщения, то сортировать по дате последнего сообщения
        const aLastMessageDate = aMessages.length
          ? new Date(aMessages[aMessages.length - 1].attributes.createdAt)
          : new Date(a.attributes.createdAt);

        const bLastMessageDate = bMessages.length
          ? new Date(bMessages[bMessages.length - 1].attributes.createdAt)
          : new Date(b.attributes.createdAt);

        // Сортировка по убыванию (новые вверху)
        return bLastMessageDate - aLastMessageDate;
      });

      setConversations(sortedConversations);
      setFilteredData(sortedConversations);
    } catch (error) {
      console.log(error);
    }
  }, [userInfo?.user.id]);

  //==================SOCKET===========================
  //socket init
  useEffect(() => {
    const newsocket = io(`${process.env.REACT_APP_BASE_URL_SERVICES}`, {
      query: {
        userId: userInfo?.user.id,
      },
    });

    setSocket(newsocket);
    console.log(`connected socket>>>`, newsocket)

    return () => newsocket.disconnect();
  }, [userInfo]);

  //connected
  useEffect(() => {
    if (socket === null) return;

    if (socket.connected) {
      onConnect();
    }

    function onConnect() {
      setIsSocketConnected(true);
    }

    function onDisconnect() {
      setIsSocketConnected(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  }, [socket]);

  //set online users
  useEffect(() => {
    if (!socket || !isSocketConnected) return;

    socket.emit("addNewUser", userInfo?.user.id);

    socket.on("me", (id) => setMe(id));

    socket.on("getOnlineUsers", (onlineUsers) => {
      setOnlineUsers(onlineUsers);
    });

    socket.on("updateOnlineUsers", (user) => {
      setOnlineUsers(user);
    });

    return () => {
      socket.off("me");
      socket.off("getOnlineUsers");
      socket.off("updateOnlineUsers");
    };
  }, [socket, isSocketConnected, userInfo]);

  //calls
  useEffect(() => {
    if (!socket || !isSocketConnected) return;

    socket.on("incomingCall", onIncomingCall);
    socket.on("webrtcSignal", completePeerConnection);
    socket.on("hangup", handleHangup);

    return () => {
      socket.off("incomingCall", onIncomingCall);
      socket.off("webrtcSignal", completePeerConnection);
      socket.off("hangup", handleHangup);
    };
  }, [
    socket,
    isSocketConnected,
    userInfo,
    onIncomingCall,
    completePeerConnection,
    handleHangup
  ]);


  useEffect(() => {
    let timeout;

    if(isCallEnded) {
      timeout = setTimeout(() => {
        setIsCallEnded(false)
      }, 3000)
    }

    return () => clearTimeout(timeout)
  },[isCallEnded])


  // old
  // calling
  useEffect(() => {
    if (!socket || !isSocketConnected) return;

    socket.on("callUser", ({ from, name: callerName, signal }) => {
      console.log(from, name, signal);
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });

    return () => {
      socket.off("callUser");
    };
  }, [socket, isSocketConnected, name]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (socket) {
  //       console.log('Sending heartbeat to server');  // Add logging to track heartbeat

  //       socket.emit("heartbeat", userInfo?.user.id);  // Send heartbeat to server

  //       socket.on("disconnect", () => {
  //         console.error("Socket disconnected unexpectedly");
  //       });
  //     }
  //   }, 30000); // Send heartbeat every 30 seconds

  //   return () => clearInterval(interval);
  // }, [socket, userInfo?.user.id]);

  //TODO

  useEffect(() => {
    if (!markAsRead) return;

    const getMessagesForMark = async () => {
      if (!userInfo?.jwt) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${markAsRead.conversationId}&filters[receiver][id]=${markAsRead.receiverId}=populate=*`
        );
        const messagesForMark = response.data.data;
        // console.log("dataMessagesForMark", messagesForMark);

        if (messagesForMark.length > 0) {
          let ids = await messagesForMark.map((m) => m.id);
          const data = {
            isRead: true,
          };

          //error for exit
          for (let i = 0; i < messagesForMark.length; i++) {
            try {
              await MessageService.update(ids[i], data, userInfo?.jwt);
            } catch (error) {
              console.log("Error update messagesForMark", error);
            }
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    getMessagesForMark();
  }, [markAsRead, userInfo?.jwt]);

  // ========= Video Call Logic =============
  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });

    try {
      peer.signal(call.signal);
    } catch (error) {
      console.error("Error during peer signaling: ", error);
    }

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);

      try {
        peer.signal(signal);
      } catch (error) {
        console.error("Error during signal acceptance: ", error);
      }
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    setCallEnded(true);

    try {
      if (connectionRef.current) {
        connectionRef.current.destroy();
      }
    } catch (error) {
      console.log("Error while destroying peer connection: ", error);
    }
  };

  //room chat
  const createNewRoom = (identity) => {
    //emit an event to server that we would like to create new room
    const data = {
      identity,
    };

    socket.emit("create-new-room", data);
  };

  const joinRoom = (roomId, identity) => {
    //emit an event to server that we would like to join room
    const data = {
      roomId,
      identity,
    };

    socket.emit("join-room", data);
  };

  // Leave video chat room without disconnecting socket
  const leaveVideoRoom = (roomId) => {
    socket.emit("leave-room", roomId); // User leaves only the video room
  };

  return (
    <ChatContext.Provider
      value={{
        conversations,
        setConversations,
        updateConversations,
        filtred,
        setFilteredData,
        selectedConversation,
        setSelectedConversation,
        messages,
        setMessages,
        updateMessages,
        socket,
        onlineUsers,
        newMessage,
        setNewMessage,
        notifications,
        setNotifications,
        markAsRead,
        setMarkAsRead,       
        localStream,
        handleJoinCall,
        handleHangup,
        peer,
        isCallEnded,        
        usersInRoom, 
        setUsersInRoom,        
        playSound,
        setPlaySound,
        call,
        setCall,
        ongoingCall,
        handleCall,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        setStream,
        name,
        setName,
        callEnded,
        me,
        setMe,
        callUser,
        leaveCall,
        answerCall,
        createNewRoom,
        joinRoom,
        leaveVideoRoom,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
