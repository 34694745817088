import React, { useContext, useEffect, useState } from "react";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import ruLocale from "date-fns/locale/ru";
import { Badge, Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import AvatarWithStatus from "../avatar/AvatarWithStatus";
import { ChatContext } from "../../providers/ChatProvider";
import axios from "axios";
import { useAuth } from "../../providers/AuthProvider";

const getMessageTime = (createdAt, lang) => {
  if (isToday(createdAt)) {
    return format(createdAt, "HH:mm", {
      locale: lang === "ru" ? ruLocale : null,
    });
  } else {
    return format(createdAt, "dd MMM", {
      locale: lang === "ru" ? ruLocale : null,
    });
  }
};

const renderLastMessage = (message, userId, senderId, lang) => {
  let markerMe = lang === "ru" ? "Вы: " : lang === "ua" ? "Ви: " : "You: ";
  let text = "";
  if (
    message?.text === "" &&
    (message?.attachment?.length || message?.image.length)
  ) {
    text =
      lang === "ru"
        ? "📎 вложенный файл"
        : lang === "ua"
        ? "📎 вкладений файл"
        : "📎 attachment file";
  } else {
    text = message?.text;
  }

  return text && `${senderId !== userId ? "" : markerMe}${text}`;
  // return text;
};

const Conversation = ({ conversation, userId }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    onlineUsers,
    messages,
    updateConversation,
    updateMessages,
    selectedConversation,
  } = useContext(ChatContext);
  const { userInfo } = useAuth();
  const [undread, setUndread] = useState("");
  const [lastMessage, setLastMessage] = useState([]);

  const partner = conversation.attributes.members.data.find(
    (member) => member.id !== userId
  );

  const senderId = lastMessage?.attributes?.sender?.data?.id;
  const isRead = lastMessage?.attributes?.isRead;
  const isOnline = onlineUsers.some((user) => user?.userId === partner?.id);
  const isSenderMy = lastMessage?.attributes?.sender?.data?.id === userId;

  let lang = i18n.language;

  useEffect(() => {
    const getCountUndread = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${conversation.id}&filters[isRead]=false&filters[receiver]=${userInfo?.user.id}&populate=*`
        );
        const res = response.data.data;
        setUndread(res.length);
      } catch (error) {
        console.log(error);
      }
    };
    getCountUndread();
  }, [conversation, userInfo?.user.id]);

  useEffect(() => {
    const getLastMessage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/api/messages?filters[conversation][id]=${conversation.id}&populate=*`
        );
        const arr_messages = response.data.data;

        const maxId = Math.max(...arr_messages.map((message) => message.id));
        const lastMessage = arr_messages.filter(
          (message) => message.id === maxId
        );

        setLastMessage(...lastMessage.slice());
      } catch (error) {
        console.log(error);
      }
    };
    getLastMessage();
  }, [conversation, messages, updateConversation, updateMessages]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="60px"
        padding={1}
        mr={1}
        sx={{
          cursor: "pointer",
          backgroundColor:
            selectedConversation?.id === conversation?.id
              ? theme.palette.mode === "dark"
                ? `${colors.primary[500]}`
                : `${colors.grey[900]}`
              : "transparent",
          "&:hover": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <AvatarWithStatus
          alt={partner?.attributes.username}
          src={partner?.attributes.avatarUrl ? `${process.env.REACT_APP_STRAPI_URL}${partner?.attributes.avatarUrl}` : null}
          isOnline={isOnline}
        />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between" // Aligns top and bottom content
          maxWidth="70px"
          height="80%" // Makes sure the block takes up the full height
        >
          <Typography
            variant="body1"
            color={theme.palette.mode === "dark" ? "white" : "black"}
          >
            {partner?.attributes?.username}
          </Typography>
          <Typography
            color="grey"
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "70px",
              height: "1.2em",
              whiteSpace: "nowrap",
            }}
          >
            {renderLastMessage(lastMessage?.attributes, userId, senderId, lang)}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between" // Space between date and icons
          alignItems="flex-end" // Align content to the right
          height="80%" // Ensures the block takes the full height
          minWidth="40px"
        >
          <Typography
            color="grey"
            variant="body2"
            sx={{
              lineHeight: 1,
            }}
          >
            {lastMessage?.attributes?.createdAt
              ? getMessageTime(
                  new Date(lastMessage?.attributes.createdAt).toUTCString(),
                  lang
                )
              : "-"}
          </Typography>

          <Box display="flex" alignItems="center">
            {!undread &&
              isSenderMy &&
              (isRead ? (
                <DoneAllIcon fontSize="small" sx={{ color: "green" }} />
              ) : (
                <DoneIcon fontSize="small" sx={{ color: "green" }} />
              ))}

            {lastMessage && undread > 0 && (
              <Badge badgeContent={undread} color="info" sx={{ mr: 1, mb: 1 }} />
            )}
          </Box>
        </Box>
      </Box>
      {/* <Divider sx={{ marginRight: "10px" }} /> */}
    </>
  );
};

export default Conversation;
