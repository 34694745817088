import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

const Assets = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    fetchAssetData();
  }, []);

  const fetchAssetData = async () => {
    try {
      const json = await fetch(
        // `${process.env.REACT_APP_STRAPI_URL}/api/assets`
        `${process.env.REACT_APP_STRAPI_URL}/api/assets?populate=*`
      );
      const res = await json.json();
      const resArray = res.data || [];
      console.log(resArray);
      
      let arr_assets = [];
      arr_assets = resArray.map((item) => ({
        id: item.id,
        account: item.attributes?.account || "",
        coin: item.attributes?.coin || "",
        symbol: item.attributes?.symbol || "",
        pathAccount: item.attributes?.pathAccount || "",
        contractAddress: item.attributes?.contractAddress || "",
        user: item.attributes?.user?.data?.attributes?.username || "",
        imgsrc: item.attributes?.imgsrc || "",
        createdAt: new Date(item.attributes.createdAt).toLocaleString(),
      }));
      // console.log(arr_assets);
      setAssets(arr_assets.slice());
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "imgsrc",
      headerName: "Img",
      flex: 0.7,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <img alt="coin" width="25px" height="25px" src={params.row.imgsrc} />
      ),
    },
    {
      field: "account",
      headerName: "Account",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "coin",
      headerName: "Coin",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "symbol",
      headerName: "Symbol",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "pathAccount",
      headerName: "Path account",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "contractAddress",
      headerName: "Contract address",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      headerAlign: "left",
      align: "left",
      cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Created at",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header title={t("ASSETS")} subtitle={t("List of Assets")} />

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            display: "flex",
            alignItems: "center",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-container--top [role=row]":{
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={assets}
          columns={columns}
          slots={{
            toolbar: GridToolbar,            
          }}
        />
      </Box>
    </Box>
  );
};

export default Assets;
