import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import Label from "./sections/Label";
import Participants from "./sections/Participants";
import GroupChat from "./sections/GroupChat";
import Video from "./sections/Video";
import Overlay from "./sections/Overlay";
import * as webRTCHandler from "../../utils/webRTCHandler";
import { useSelector } from "react-redux";

const Room = () => {
  const { isRoomHost, identity, roomId, showOverlay, connectOnlyWithAudio } =
    useSelector((state) => state.app);

  useEffect(() => {
    if (!isRoomHost && !roomId) {
      const siteURL = window.location.origin;
      window.location.href = siteURL;
    } else {
      webRTCHandler.getLocalPreviewAndInitRoomConnection(
        isRoomHost,
        identity,
        roomId,
        connectOnlyWithAudio
      );
    }
  }, []);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: "relative",
        width: 1,
        height: "100vh",
      }}
    >
      <Participants />
      <Label />
      <Video />
      <GroupChat />
      {showOverlay && <Overlay />}
    </Stack>
  );
};

export default Room;
