import React, { useContext } from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../providers/ChatProvider";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";

const CallNotification = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { ongoingCall, handleJoinCall, handleHangup } = useContext(ChatContext);

  if (!ongoingCall?.isRinging) return;

  return (
    <Box
      sx={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        bgcolor: (theme) => alpha(theme.palette.grey[700], 0.4),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 12,
      }}
    >
      <Box
        sx={{
          padding: 3,
          minWidth: 300,
          bgcolor: "white",
          minHeight: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar />
        <Typography color="primary" variant="h4" mt={2}>
          {t("Incoming Call")}
        </Typography>

        <Box display="flex" flexDirection="row" gap={5} p={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 0.5,
              borderRadius: 10,
              bgcolor: "green",
            }}
          >
            <IconButton
              onClick={() => handleJoinCall(ongoingCall)}
              sx={{ color: theme.palette.common.white }}
            >
              <CallOutlinedIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 0.5,
              borderRadius: 10,
              bgcolor: theme.palette.error.main,
            }}
          >
            <IconButton
              onClick={() =>
                handleHangup({
                  ongoingCall: ongoingCall ? ongoingCall : undefined,
                  isEmitHangup: true
                })
              }
              sx={{ color: theme.palette.common.white }}
            >
              <CallEndOutlinedIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CallNotification;
