import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatService } from "../../api/chat.service";
import { useDispatch, useSelector } from "react-redux";
import {
  SetConnectOnlyWithAudio,
  SetIdentity,
  SetRoomId,
  UpdateIsRoomHost,
} from "../../redux/slices/app";

const Join = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isRoomHost, connectOnlyWithAudio } = useSelector(
    (state) => state.app
  );
  const [roomIdValue, setRoomIdValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const search = useLocation().search;
  const titleText = isRoomHost ? t("Host Meeting") : t("Join Meeting");

  const handleChange = (event) => {
    dispatch(SetConnectOnlyWithAudio(event.target.checked));
  };

  const handleJoinRoom = async () => {
    dispatch(SetIdentity(nameValue));

    if (isRoomHost) {
      createRoom();
    } else {
      joinRoom();
    }
  };

  const createRoom = () => {
    navigate("/room");
  };

  const joinRoom = async () => {
    const response = await ChatService.getRoomExist(roomIdValue);

    const { roomExists, full } = response;

    if (roomExists) {
      if (full) {
        setErrorMessage("Meeting is full. Please try again later");
      } else {
        dispatch(SetRoomId(roomIdValue));
        navigate("/room");
      }
    } else {
      setErrorMessage("Meeting not found. Check your meeting ID please");
    }
  };

  const pushToIntroPage = () => {
    navigate("/intro-room");
  };

  useEffect(() => {
    const isRoomHost = new URLSearchParams(search).get("host");

    //set in redux
    dispatch(UpdateIsRoomHost(isRoomHost));
  }, []);

  return (
    <Container>
      <Box
        sx={{
          width: 1,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            px: 4,
            py: 8,
            width: 400,
            rowGap: 6,
          }}
        >
          <Stack spacing={2} sx={{ width: 1 }}>
            <Typography variant="h3" textAlign="center">
              {titleText}
            </Typography>
            {!isRoomHost && (
              <TextField
                color="secondary"
                size="small"
                fullWidth
                placeholder={t("Enter meeting ID")}
                value={roomIdValue}
                onChange={(e) => setRoomIdValue(e.target.value)}
              />
            )}
            <TextField
              color="secondary"
              size="small"
              fullWidth
              placeholder={t("Enter your name")}
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
            />
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Checkbox
                color="secondary"
                checked={connectOnlyWithAudio}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="subtitle2">{t("Audio only")}</Typography>
            </Stack>
            {errorMessage && (
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.error.main }}
              >
                {errorMessage}
              </Typography>
            )}
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleJoinRoom}
              >
                {isRoomHost ? t("Host") : t("Join")}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={pushToIntroPage}
              >
                {t("Cancel")}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </Container>
  );
};

export default Join;
