import {
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { ChatContext } from "../../../providers/ChatProvider";
import { useAuth } from "../../../providers/AuthProvider";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getOpenAIResponse } from "../../../api/openai.service";
import { useSelector } from "react-redux";
import * as webRTCHandler from "../../../utils/webRTCHandler";
import { useTranslation } from "react-i18next";

const GroupChat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userInfo } = useAuth();
  const { socket } = useContext(ChatContext);
  const { identity, roomId, messages } = useSelector((state) => state.app);

  const [conversationHistory, setConversationHistory] = useState([]);
  const [input, setInput] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [messageInput, setMessageInput] = useState("");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSendMessage = () => {
    if (messageInput.length > 0) {
      webRTCHandler.sendMessageUsingDataChannel(messageInput);
      setMessageInput("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      try {
        const aiResponse = await getOpenAIResponse(input);
        const aiText =
          aiResponse?.choices[0]?.message?.content || "No response";
        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { sender: "User", text: input },
          { sender: "AI", text: aiText },
        ]);
      } catch (error) {
        console.error("Error fetching AI response:", error);
      }
      setInput("");
    }
  };

  return (
    <Box
      sx={{
        width: "20%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        borderLeft: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="group chat tabs"
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab label={t("GroupChat")} />
        <Tab label={t("GPTchat")} />
      </Tabs>

      {/* Content of the selected tab */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          p: 2,
        }}
      >
        {selectedTab === 1 && (
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={1} sx={{ flex: 1, overflowY: "auto" }}>
              {conversationHistory.map((entry, index) => (
                <Box
                  key={index}
                  sx={{
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    backgroundColor:
                      entry.sender === "AI"
                        ? theme.palette.grey[500]
                        : theme.palette.primary.light,
                    alignSelf:
                      entry.sender === "AI" ? "flex-start" : "flex-end",
                  }}
                >
                  <Typography variant="body2">
                    <strong>{entry.sender}:</strong> {entry.text}
                  </Typography>
                </Box>
              ))}
            </Stack>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                pt: 2,
              }}
            >
              <TextField
                color="secondary"
                size="small"
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={t("Type your prompt here...")}
              />
              <IconButton type="submit">
                <SendOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        )}

        {selectedTab === 0 && (
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Stack spacing={1} sx={{ flex: 1, overflowY: "auto" }}>
              {messages.map((message, index) => (
                <Box
                  key={`${message.content}${index}`}
                  sx={{
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    backgroundColor: message.messageCreatedByMe
                      ? theme.palette.grey[500]
                      : theme.palette.primary.light,
                    alignSelf: message.messageCreatedByMe
                      ? "flex-end"
                      : "flex-start",
                  }}
                >
                  <Typography variant="body2">
                    <strong>{message.identity}</strong>: {message.content}
                  </Typography>
                </Box>
              ))}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                color="secondary"
                size="small"
                fullWidth
                placeholder={t("Type a message")}
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              />
              <IconButton onClick={handleSendMessage}>
                <SendOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GroupChat;
