import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import {alpha} from '@mui/material/styles'

const Overlay = () => {
  return (
    <Box sx={{
        position: "absolute",
        height: '100vh',
        width: '100vw',
        bgcolor: (theme) => alpha(theme.palette.grey[700], 0.6),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    }}
    >
        <CircularProgress />

    </Box>
  );
};

export default Overlay