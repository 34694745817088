import axios from 'axios';

const API_KEY = process.env.REACT_APP_API_KEY_OPENAI; //SP GPT-4o

const openai = axios.create({
    baseURL: 'https://api.openai.com/v1',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${API_KEY}`,
    },
  });
  
  export const getOpenAIResponse = async (prompt) => {
    try {
      const response = await openai.post('/chat/completions', {
        model: 'gpt-4',  
        messages: [
          { role: 'user', content: prompt }, 
        ],
        max_tokens: 500,
      });
  
      return response.data;
    } catch (error) {
      console.error("Error fetching response from OpenAI:", error);
      return null;  
    }
  };