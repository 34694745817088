import { useTheme } from "@emotion/react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DirectChat from "./DirectChat";
import { SetActiveConversation } from "../../../redux/slices/app";
import { store } from "../../../redux/store";

const SingleParticipant = (props) => {
  const { identity, lastItem, participant, socketId } = props;

  const handleOpenActiveChatbox = () => {
    if (participant.socketId !== socketId) {
      store.dispatch(SetActiveConversation(participant));
    }
  };

  return (
    <>
      <Box
        onClick={handleOpenActiveChatbox}
        sx={{
          px: 2,
          py: 1,
          borderRadius: 2,
          "&:hover": {
            bgcolor: "grey",
          },
        }}
      >
        <Typography>{identity}</Typography>
      </Box>
      {!lastItem && <Divider />}
    </>
  );
};

const Participants = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { participants, setActiveConversation, socketId } = useSelector(
    (state) => state.app
  );

  return (
    <Box
      sx={{
        width: "20%",
        height: 1,
        display: "flex",
        flexDirection: "column",
        borderRight: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          p: 2,
          height: "50%",
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
        }}
      >
        <Typography variant="subtitle1" >
          {t("PARTICIPANTS")}
        </Typography>
        <Stack>
          {participants.map((participant, index) => {
            return (
              <SingleParticipant
                key={participant.identity}
                lastItem={participants.length === index + 1}
                participant={participant}
                identity={participant.identity}
                setActiveConversation={setActiveConversation}
                socketId={socketId}
              />
            );
          })}
        </Stack>
      </Box>

      <Box>
        <DirectChat />
      </Box>
    </Box>
  );
};

export default Participants;
