import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import { tokens } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Label = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { roomId } = useSelector((state) => state.app);

  const handleCopy = async () => {
    try {
      const text = roomId;
      // Копирование текста в буфер обмена
      await navigator.clipboard.writeText(text);

      // TODO: Показываем уведомление
      alert(t("Successfully copied to clipboard"));
    } catch (err) {
      console.error("Ошибка при копировании текста: ", err);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        py: 2,
        px: 6,
        bgcolor: colors.blueAccent[600],
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        zIndex: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        onClick={handleCopy}
        sx={{ cursor: "pointer" }}
      >
        {t("ROOM ID")}: {roomId}
      </Typography>
    </Box>
  );
};

export default Label;
