import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,  
  InputBase,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useAuth } from "../../providers/AuthProvider";
import { ChatContext } from "../../providers/ChatProvider";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Picker from "@emoji-mart/react";
import format from "date-fns/format";
import data from "@emoji-mart/data";
import { MessageService } from "../../api/message.service";
import { useAlert } from "../../hooks/useAlert";

const MessageInput = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userInfo } = useAuth();
  const showAlert = useAlert();
  const {
    selectedConversation,
    messages,
    setMessages,
    updateMessages,
    socket,
    newMessage,
    setNewMessage,
    onlineUsers,
  } = useContext(ChatContext);

  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(null);
  const [dragOver, setDragOver] = useState(false); // Handle drag state
  const [image, setImage] = useState(null); // Handle pasted image
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null); // Track editing state
  const [isOnline, setIsOnline] = useState(false);

  // Partner information
  const partnerId = selectedConversation?.attributes.members.data[0].id;
  let idxPartner = partnerId === userInfo?.user.id ? 1 : 0;
  let idxUser = partnerId === userInfo?.user.id ? 0 : 1;
  const partner = selectedConversation?.attributes.members.data[idxPartner];
  const user = selectedConversation?.attributes.members.data[idxUser];

  const checkIfUserIsOnline = () => {
    return (
      Array.isArray(onlineUsers) &&
      onlineUsers.some((user) => user?.userId === partner?.id)
    );
  };

  // Update isOnline when onlineUsers changes
  useEffect(() => {
    setIsOnline(checkIfUserIsOnline());
  }, [onlineUsers, partnerId]);

  const isTyping = () =>
    socket.emit("typing", {
      conversationId: selectedConversation.id,
      receiverId: partner.id,
      status: `${userInfo?.user.username}`,
    });

  // Add Emoji on message
  const addEmoji = (emojiTag) => {
    setNewMessage(newMessage + emojiTag.native);
    setEmojiPickerVisible(false);
  };

  const toggleEmojiPicker = () => {
    setEmojiPickerVisible(!emojiPickerVisible);
  };

  const cancelEditing = () => {
    setEditingMessage(null);
    setNewMessage("");
  };

  const cancelFile = () => {
    setFile(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    } else if (e.key === "ArrowUp") {
      const lastMessage = messages
        .filter((msg) => msg.attributes.sender.data.id === userInfo?.user.id)
        .slice(-1)[0];
      if (lastMessage) {
        setNewMessage(lastMessage.attributes.text);
        setEditingMessage(lastMessage);
      } else {
        setNewMessage("");
      }
    }
  };

  const handleCloseDialog = () => {
    setModal(false);
    setImage(null); // Reset the image when closing the dialog
  };

  const handleFileChange = ({ target: { files } }) => {
    if (files?.length) {
      const { type } = files[0];
      if (type === "image/png" || type === "image/jpeg") {
        setFile(files[0]);
      } else {
        showAlert(
          t("Accept only png and jpeg image types are allowed*"),
          "error"
        );
      }
    }
  };

  // Handle pasting text and images
  const handlePaste = (e) => {
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // Check if the clipboard item is an image
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        const reader = new FileReader();

        reader.onload = function (event) {
          setImage(event.target.result); // Set image as base64
          setModal(true);
        };

        reader.readAsDataURL(file);
      }

      // Check if the clipboard item is text
      if (item.type === "text/plain") {
        item.getAsString((text) => {
          setNewMessage((prevMessage) => prevMessage + text);
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage && !image && !file) return;

    // Check if editing or sending a new message
    if (editingMessage) {
      await updateMessage(editingMessage.id, newMessage);
    } else {
      await sendMessage(newMessage);
    }

    setNewMessage("");
    setImage(null);
    setFile(null);
    setEditingMessage(null);
    setModal(false);
  };

  const updateMessage = async (messageId, updatedText) => {
    const id = messageId;
    const data = { text: updatedText };

    try {
      await MessageService.update(id, data, userInfo.jwt);
      updateMessages();
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async (messageText) => {
    if (!selectedConversation.id) return;

    // upload File, if exsist
    let attachmentId = null;
    let attachmentUrl = null;
    if (file) {
      const uploadResult = await uploadFileToBackend(file);
      attachmentId = uploadResult.id;
      attachmentUrl = uploadResult.url;
    }

    // Upload the image if it exists
    let imageId = null;
    let imageUrl = null;
    if (image) {
      // imageUrl = await uploadImageToStrapi(image);
      const imageUploadResult = await uploadImageToBackend(image);
      imageId = imageUploadResult.id;
      imageUrl = imageUploadResult.url;
    }

    const data = {
      sender: userInfo?.user.id,
      receiver: partner.id,
      text: messageText,
      conversation: selectedConversation.id,
      assetId: attachmentId || imageId,
      attachment: attachmentUrl,
      image: imageUrl,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_STRAPI_URL}/api/messages?populate=*`,
        { data },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userInfo?.jwt}`,
          },
        }
      );

      const newDataMessage = res.data.data;
      setMessages([...messages, newDataMessage]);
      updateMessages();

      if (!isOnline) {
        sendNotification(partner?.attributes.email);
        //console.log(`Send notification (ID: ${partner.attributes.email}). User offline.`);
      } else {
        console.log(
          `No Send notification (ID: ${partner.attributes.email}). User online.`
        );
      }

      // socket OUT
      socket.emit("sendMessage", newDataMessage);
    } catch (error) {
      console.log(error);
    }
  };

  const sendNotification = async (subscriberId) => {
    const textMessage = t("You have a new message from user ");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_SERVICES}/api/send-notification`,
        {
          subscriberId: subscriberId,
          title: t("ATTENTION!"),
          message: `${textMessage}${userInfo?.user.username}.`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Notification sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const uploadFileToBackend = async (file) => {
    let currentDate = format(new Date().toUTCString(), "ddMMyy");
    let filename = `conv_${selectedConversation.id}_${currentDate}`;

    const formData = new FormData();
    formData.append("files", file, filename);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `bearer ${userInfo?.jwt}`,
          },
        }
      );
      const { data } = response;

      const id = data[0]?.id;
      const url = data[0]?.url;

      return { id, url };
    } catch (error) {
      console.log(error);
      showAlert(t("Failed to upload file"), "error");
      return null;
    }
  };

  const uploadImageToBackend = async (base64Image) => {
    // Convert base64 to Blob
    const response = await fetch(base64Image);
    const blob = await response.blob();

    let currentDate = format(new Date().toUTCString(), "ddMMyy");
    let filename = `conv_${selectedConversation.id}_${currentDate}`;

    // Prepare form data for Strapi
    const formData = new FormData();
    formData.append("files", blob, filename);

    const uploadResponse = await axios.post(
      `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${userInfo?.jwt}`,
        },
      }
    );
    const { data } = uploadResponse;

    const id = data[0]?.id;
    const url = data[0]?.url;

    return { id, url };
  };

  return (
    <>
      <Box>
        {editingMessage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              backgroundColor: colors.primary[500],
              borderRadius: "5px",
              position: "absolute",
              bottom: 65,
              left: 0,
              right: 0,
              height: "40px",
            }}
          >
            <Box display="flex" flexDirection="row">
              <ModeEditIcon fontSize="small" color="secondary" />
              <Box ml={1}>
                <Typography variant="body2" color="secondary">
                  {t("Editing a message:")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {editingMessage.attributes.text}
                </Typography>
              </Box>
            </Box>

            <IconButton onClick={cancelEditing}>
              <ClearOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          </Box>
        )}

        {file && !editingMessage && (
          <>
            <Box sx={{ marginLeft: "10px", color: colors.greenAccent[500] }}>
              {file.name}
              <ClearOutlinedIcon onClick={cancelFile} fontSize="small" />
            </Box>
          </>
        )}

        <Box
          component="form"
          onPaste={handlePaste}
          onSubmit={handleSubmit}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: dragOver ? colors.primary[600] : "inherit",
            borderRadius: "8px",
            border: dragOver ? `2px dashed ${colors.greenAccent[500]}` : "none",
            transition: "background-color 0.2s ease-in-out",
            height: "80px",
            position: "relative",
            bottom: 0,
            // padding: "10px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            mr={2}
            sx={{
              position: "absolute",
              bottom: 15,
              border: `2px solid ${colors.greenAccent[800]}`,
              width: "100%",
            }}
          >
            {emojiPickerVisible && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 60,
                  left: 10,
                  zIndex: 1000,
                }}
              >
                <Picker data={data} onEmojiSelect={addEmoji} emojiSize={20} />
              </Box>
            )}

            <IconButton onClick={toggleEmojiPicker} size="20">
              😄
            </IconButton>

            <InputBase
              color="secondary"
              fullWidth
              placeholder={t("Send message")}
              sx={{ minWidth: "100px", marginLeft: "10px" }}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              onKeyUp={isTyping}
              value={newMessage}
            />

            <IconButton component="label" sx={{ width: "43px" }}>
              <AttachFileOutlinedIcon />
              <input
                type="file"
                hidden
                onChange={(e) => setFile(e.target.files[0])}
              />
            </IconButton>

            <IconButton sx={{ width: "43px" }} type="submit">
              <SendOutlinedIcon />
            </IconButton>

            <Dialog open={modal} onClose={handleCloseDialog}>
              <DialogTitle>{t("SEND IMAGE")}</DialogTitle>
              <DialogContent>
                {image && (
                  <img
                    src={image}
                    alt="Pasted"
                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleCloseDialog}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleSubmit}
                  autoFocus
                >
                  {t("SEND")}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MessageInput;
