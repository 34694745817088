import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  identity: "",
  isRoomHost: false,
  connectOnlyWithAudio: false,
  roomId: null,
  showOverlay: true,
  participants: [],
  messages: [],
  activeConversation: null,
  directChatHistory: [],
  socketId: null,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateIsRoomHost(state, action) {
      state.isRoomHost = action.payload;
    },
    updateIdentity(state, action) {
      state.identity = action.payload;
    },
    updateRoomId(state, action) {
      state.roomId = action.payload;
    },
    updateConnectOnlyWithAudio(state, action) {
      state.connectOnlyWithAudio = action.payload;
    },
    updateOverlay(state, action) {
      state.showOverlay = action.payload;
    },
    updateParticipants(state, action) {
      state.participants = action.payload;
    },
    updateMessages(state, action) {
      state.messages = action.payload;
    },    
    updateActiveConversation(state, action) {
      state.activeConversation = action.payload;
    },
    updateDirectChatHistory(state, action) {
      state.directChatHistory = action.payload;
    },
    updateSocketId(state, action) {
      state.socketId = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

//Actions
export const {
  updateIsRoomHost,
  updateIdentity,
  updateRoomId,
  updateConnectOnlyWithAudio,
  updateOverlay,
  updateParticipants,
  updateMessages,
  updateDirectChatHistory,
  updateActiveConversation,
  updateSocketId,
} = slice.actions;

export function UpdateIsRoomHost(value) {
  return async (dispatch) => {
    dispatch(updateIsRoomHost(value));
  };
}

export function SetIdentity(value) {
  return async (dispatch) => {
    dispatch(updateIdentity(value));
  };
}

export function SetRoomId(value) {
  return async (dispatch) => {
    dispatch(updateRoomId(value));
  };
}

export function SetConnectOnlyWithAudio(value) {
  return async (dispatch) => {
    dispatch(updateConnectOnlyWithAudio(value));
  };
}

export function SetShowOverlay(value) {
  return async (dispatch) => {
    dispatch(updateOverlay(value));
  };
}

export function SetParticipants(participants) {
  return async (dispatch) => {
    dispatch(updateParticipants(participants));
  };
}

export function SetMessages(messages) {
  return async (dispatch) => {
    dispatch(updateMessages(messages));
  };
}

export function SetDirectChatHistory(directChatHistory) {
  return async (dispatch) => {
    dispatch(updateDirectChatHistory(directChatHistory));
  };
}

export function SetActiveConversation(value) {
  return async (dispatch) => {
    dispatch(updateActiveConversation(value));
  };
}

export function SetSocketId(value) {
  return async (dispatch) => {
    dispatch(updateSocketId(value));
  };
}
