import { Logout } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VolumeDownOutlinedIcon from "@mui/icons-material/VolumeDownOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import { useAuth } from "../../providers/AuthProvider";
import { ConversationService } from "../../api/conversation.service";
import { MessageService } from "../../api/message.service";
import { ChatContext } from "../../providers/ChatProvider";
import { useAlert } from "../../hooks/useAlert";
import axios from "axios";

const TopChatMenu = ({
  anchorChatMenu,
  setAnchorChatMenu,
  isLoading,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = useState(false);
  const [openClean, setOpenClean] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const { userInfo } = useAuth();
  const showAlert = useAlert();
  const {
    messages,
    setMessages,
    updateMessages,
    selectedConversation,
    setSelectedConversation,
    updateConversations,
    socket,
    playSound,
    setPlaySound,
  } = useContext(ChatContext);

  if (!selectedConversation) return null;

  const members = selectedConversation?.attributes?.members?.data || [];
  const partner = members.find((member) => member.id !== userInfo?.user.id);
  const partnerId = partner?.id;

  const handleSound = () => {
    setPlaySound(!playSound);
  };

  const handleClickOpenDialogLogout = () => {
    setOpenLogout(true);
  };

  const handleCloseDialogLogout = () => {
    setOpenLogout(false);
  };

  const handleClickOpenDialogClean = () => {
    setOpenClean(true);
  };

  const handleCloseDialogClean = () => {
    setOpenClean(false);
  };

  const handleClickOpenDialogRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseDialogRemove = () => {
    setOpenRemove(false);
  };

  const handleCloseChatMenu = () => {
    setAnchorChatMenu(null);
  };

  const handleLogout = () => {
    navigate(`/`);
  };

  const handleClickClean = () => {
    cleanMessageConversation();
    setOpenClean(false);
  };

  const handleClickRemove = () => {
    removeConversation();
    setOpenRemove(false);
  };

  // remove attachment file
  const removeFile = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_STRAPI_URL}/api/upload/files/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userInfo?.jwt}`,
          },
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  //TODO
  const cleanMessageConversation = async () => {
    if (messages.length === 0) return;

    setIsLoading(true);

    try {
      // Удаляем все файлы, если они существуют
      await Promise.all(
        messages
          .filter((message) => message.attributes.assetId)
          .map((message) => removeFile(message.attributes.assetId))
      );

      // Удаляем все сообщения
      await Promise.all(
        messages.map((message) =>
          MessageService.delete(message.id, userInfo?.jwt)
        )
      );

      setMessages([]);

      const cleanMessages = {
        conversationId: selectedConversation?.id,
        receiverId: partnerId,
      };

      // socket OUT
      socket.emit("cleanMessages", cleanMessages);

      await updateMessages();
      await updateConversations();

      showAlert(t("Delete messages successfully"), "success");
      setIsLoading(false);
    } catch (error) {
      console.log("Error delete", error);
      setIsLoading(false);
    }
  };

  const removeConversation = async () => {
    if (!selectedConversation) return;

    if (messages.length > 0) {
      await cleanMessageConversation();
    }

    try {
      await ConversationService.delete(selectedConversation?.id, userInfo?.jwt);
      showAlert(t("Delete conversation successfully"), "success");

      const dataRemoveConversation = {
        conversationId: selectedConversation?.id,
        receiverId: partnerId,
      };

      // socket OUT
      socket.emit("removeConversation", dataRemoveConversation);

      setSelectedConversation(null);
      await updateConversations();
    } catch (error) {
      console.log("Error delete", error);
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorChatMenu}
        open={Boolean(anchorChatMenu)}
        onClose={handleCloseChatMenu}
        onClick={handleCloseChatMenu}
      >
        <MenuItem onClick={handleSound}>
          <ListItemIcon>
            {playSound ? (
              <VolumeOffOutlinedIcon fontSize="small" />
            ) : (
              <VolumeDownOutlinedIcon fontSize="small" />
            )}
          </ListItemIcon>
          {playSound ? t("OFF sound") : t("ON sound")}
        </MenuItem>

        <MenuItem onClick={handleClickOpenDialogClean}>
          <ListItemIcon>
            <CleaningServicesOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("Clear history")}
        </MenuItem>

        <MenuItem onClick={handleClickOpenDialogRemove}>
          <ListItemIcon>
            <DeleteOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("Delete dialog")}
        </MenuItem>

        <MenuItem onClick={handleClickOpenDialogLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>

      {/* dialog logout */}
      <Dialog
        open={openLogout}
        onClose={handleCloseDialogLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ATTENTION!")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Do you really want to logout of the CHAT?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialogLogout}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleLogout}
            autoFocus
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog clean messages */}
      <Dialog
        open={openClean}
        onClose={handleCloseDialogClean}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ATTENTION!")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Do you really want to clear the conversation messages?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialogClean}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClickClean}
            autoFocus
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog remove Conversation */}
      <Dialog
        open={openRemove}
        onClose={handleCloseDialogRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("ATTENTION!")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Do you really want to remove this CONVERSATION?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseDialogRemove}
          >
            {t("CANCEL")}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClickRemove}
            autoFocus
          >
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopChatMenu;
