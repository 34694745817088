import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { ChatContext } from "../../providers/ChatProvider";
import { Logout, Phone } from "@mui/icons-material";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import CommentsDisabledOutlinedIcon from "@mui/icons-material/CommentsDisabledOutlined";
import VideoPlayer from "../../components/videochat/VideoPlayer";
import VidChat from "../../components/videochat/VidChat";
import CallNotification from "../../components/videochat/CallNotification";

// Main container videochat
const VideoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isChatOn",
})(({ isChatOn }) => ({
  flex: 1,
  position: "relative",
  maxWidth: isChatOn ? "55%" : "100%",
  height: "70vh",
  backgroundColor: "#000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.3s ease",
  ...(isChatOn ? {} : {}),
}));

const ChatWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isChatOn",
})(({ isChatOn }) => ({
  flex: 1,
  position: "relative",
  maxWidth: isChatOn ? "45%" : "0%",
  height: "70vh",
  display: isChatOn ? "flex" : "none",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.3s ease",
  ...(isChatOn ? {} : {}),
}));

// Container local Video
const LocalVideoContainer = styled(Box)({
  position: "absolute",
  bottom: "10px",
  right: "10px",
  width: "150px",
  height: "100px",
  border: "2px solid white",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  zIndex: 10,
});

// Control panel
const ControlPanel = ({
  toggleMic,
  toggleCamera,
  toggleChat,
  isMicOn,
  isVidOn,
  isChatOn,
  ongoingCall,
  handleHangup,
}) => (
  <Box sx={{ position: "absolute", bottom: "10px", width: "100%" }}>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      {/* Кнопка микрофона */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
          borderRadius: 10,
          bgcolor: "grey.400",
        }}
      >
        <IconButton onClick={toggleMic} sx={{ color: "black" }}>
          {isMicOn ? <MicOffOutlinedIcon /> : <MicNoneOutlinedIcon />}
        </IconButton>
      </Box>

      {/* Кнопка камеры */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
          borderRadius: 10,
          bgcolor: "grey.400",
        }}
      >
        <IconButton onClick={toggleCamera} sx={{ color: "black" }}>
          {isVidOn ? <VideocamOffOutlinedIcon /> : <VideocamOutlinedIcon />}
        </IconButton>
      </Box>

      {/* Кнопка завершения вызова */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
          borderRadius: 10,
          bgcolor: "error.main",
        }}
      >
        <IconButton
          sx={{ color: "white" }}
          onClick={() =>
            handleHangup({
              ongoingCall: ongoingCall ? ongoingCall : undefined,
              isEmitHangup: true,
            })
          }
        >
          <CallEndOutlinedIcon />
        </IconButton>
      </Box>

      {/* Кнопка чата */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
          borderRadius: 10,
          bgcolor: "grey.400",
        }}
      >
        <IconButton onClick={toggleChat} sx={{ color: "black" }}>
          {isChatOn ? (
            <CommentsDisabledOutlinedIcon />
          ) : (
            <CommentOutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Stack>
  </Box>
);

const VideoChat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const {
    onlineUsers,
    localStream,
    handleCall,
    peer,
    ongoingCall,
    handleHangup,
    isCallEnded,
  } = useContext(ChatContext);
  const { state } = useLocation();
  const { partner } = state || {};

  const [isMicOn, setIsMicOn] = useState(true);
  const [isVidOn, setIsVidOn] = useState(true);
  const [isChatOn, setIsChatOn] = useState(false);

  useEffect(() => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      setIsVidOn(videoTrack.enabled);

      const audioTrack = localStream.getAudioTracks()[0];
      setIsMicOn(audioTrack.enabled);
    }
  }, [localStream]);

  const toggleCamera = useCallback(() => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setIsVidOn(videoTrack.enabled);
    }
  }, [localStream]);

  const toggleMic = useCallback(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setIsMicOn(audioTrack.enabled);
    }
  }, [localStream]);

  const toggleChat = () => {
    setIsChatOn((prev) => !prev);
  };

  const handleExit = () => {
    navigate("/chat");
  };

  if (!partner) {
    return (
      <Box>
        <Typography color="white">{t("No partner found!")}</Typography>
      </Box>
    );
  }

  const partnerSocketId = onlineUsers?.find(
    (onlineUser) => onlineUser.userId === partner?.id
  );

  const isOnCall = localStream && peer && ongoingCall ? true : false;

  if (isCallEnded) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Typography variant="h2" color="secondary">
          {t("Call Ended!")}
        </Typography>
      </Box>
    );
  }

  //TODO (при завершении звонка, отображается окно вызова)
  // if (!localStream && !peer) return

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CallNotification />
      <Box sx={{ padding: 5 }}>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Typography textAlign="center" variant="h3">
            {t("VideoCall with")}
          </Typography>
          <Typography textAlign="center" color="secondary" variant="h3">
            {partner?.attributes?.username || "Unknown"}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Phone fontSize="large" />}
            onClick={() => handleCall(partnerSocketId)}
            sx={{
              width: 180,
            }}
          >
            {t("Call")}
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Logout fontSize="large" />}
            onClick={handleExit}
            sx={{
              width: 180,
            }}
          >
            {t("Back to chat")}
          </Button>
        </Stack>
      </Box>

      <Box width="80%">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          width="100%"
          height="100%"
          sx={{ transition: "all 0.3s ease" }}
        >
          <VideoWrapper isChatOn={isChatOn}>
            {/* Main stream (peer) or placeholder */}
            {peer && peer.stream ? (
              <VideoPlayer
                stream={peer.stream}
                isLocalStream={false}
                isOnCall={isOnCall}
              />
            ) : (
              <Box style={{ color: "white" }}>
                {localStream && (
                  <Typography>
                    {t("Waiting for the interlocutor to connect...")}
                  </Typography>
                )}
              </Box>
            )}

            {/* Local stream (small video) */}
            {localStream && (
              <LocalVideoContainer>
                <VideoPlayer
                  stream={localStream}
                  isLocalStream={true}
                  isOnCall={isOnCall}
                />
              </LocalVideoContainer>
            )}

            {/* Control panel */}
            <ControlPanel
              toggleMic={toggleMic}
              toggleCamera={toggleCamera}
              toggleChat={toggleChat}
              isMicOn={isMicOn}
              isVidOn={isVidOn}
              isChatOn={isChatOn}
              ongoingCall={ongoingCall}
              handleHangup={handleHangup}
            />
          </VideoWrapper>

          {/* Chat */}
          <ChatWrapper isChatOn={isChatOn}>
            {isChatOn && (
              <Box
                display="flex"
                sx={{ bgcolor: colors.primary[400], flex: 1, height: 1 }}
              >
                <VidChat />
              </Box>
            )}
          </ChatWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoChat;
