import { useTheme } from "@emotion/react";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import PersonalVideoOutlinedIcon from "@mui/icons-material/PersonalVideoOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import * as webRTCHandler from "../../../utils/webRTCHandler";
import LocalScreenSharingPreview from "./LocalScreenSharingPreview";
import { useSelector } from "react-redux";
import {alpha} from '@mui/material/styles'

const constraints = {
  audio: false,
  video: true,
};

const Video = () => {  
  const theme = useTheme();
  const { connectOnlyWithAudio } = useSelector((state) => state.app);

  const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
  const [cameraEnabled, setCameraEnabled] = useState(true);
  const [screenSharingEnabled, setScreenSharingEnabled] = useState(false);
  const [screenSharingStream, setScreenSharingStream] = useState(null);

  const handleToggleMicrophone = () => {
    webRTCHandler.toggleMic(microphoneEnabled);
    setMicrophoneEnabled((prev) => !prev);
  };

  const handleToggleCamera = () => {
    webRTCHandler.toggleCamera(cameraEnabled);
    setCameraEnabled((prev) => !prev);
  };

  const handleToggleScreenShare = async () => {
    if (!screenSharingEnabled) {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getDisplayMedia(constraints);
      } catch (err) {
        console.log(
          "error occurred when trying to get an access to screen share stream"
        );
      }
      if (stream) {
        setScreenSharingStream(stream);

        webRTCHandler.toggleScreenShare(screenSharingEnabled, stream);
        setScreenSharingEnabled(true);
        // execute here function to switch the video track which we are sending to other users
      }
    } else {
      webRTCHandler.toggleScreenShare(screenSharingEnabled);
      setScreenSharingEnabled(false);

      // stop screen share stream
      screenSharingStream.getTracks().forEach((t) => t.stop());
      setScreenSharingStream(null);
    }
  };

  const handleDisconnect = () => {
    const siteURL = window.location.origin;
    window.location.href = siteURL;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "relative",
        height: 1,
      }}
    >
      {/* Inject video element */}

      {/* Controls panel */}
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          left: "50%",
          transform: "translateX(-50%)",
          width: "80%",
          // bgcolor: theme.palette.primary.light,
          bgcolor: (theme) => alpha(theme.palette.grey[700], 0.1),
          height: 80,
          borderRadius: 20,
        }}
      >
        <Stack
          sx={{ height: 1 }}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          {screenSharingEnabled && (
            <LocalScreenSharingPreview stream={screenSharingStream} />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 0.5,
              // borderRadius: 4,
              borderRadius: 10,
              bgcolor: theme.palette.grey[400],
            }}
          >
            <IconButton
              onClick={handleToggleMicrophone}
              sx={{ color: "black" }}
            >
              {microphoneEnabled ? (
                <MicNoneOutlinedIcon />
              ) : (
                <MicOffOutlinedIcon />
              )}
            </IconButton>
          </Box>

          {!connectOnlyWithAudio && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 0.5,
                borderRadius: 10,
                bgcolor: theme.palette.grey[400],
              }}
            >
              <IconButton onClick={handleToggleCamera} sx={{ color: "black" }}>
                {cameraEnabled ? (
                  <VideocamOutlinedIcon />
                ) : (
                  <VideocamOffOutlinedIcon />
                )}
              </IconButton>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 0.5,
              borderRadius: 10,
              bgcolor: theme.palette.error.main,
            }}
          >
            <IconButton
              onClick={handleDisconnect}
              sx={{ color: theme.palette.common.white }}
            >
              <CallEndOutlinedIcon />
            </IconButton>
          </Box>

          {!connectOnlyWithAudio && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 0.5,
                borderRadius: 10,
                bgcolor: theme.palette.grey[400],
              }}
            >
              <IconButton
                onClick={handleToggleScreenShare}
                sx={{ color: "black" }}
              >
                {!screenSharingEnabled ? (
                  <PersonalVideoOutlinedIcon />
                ) : (
                  <StopCircleOutlinedIcon />
                )}
              </IconButton>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Video;
