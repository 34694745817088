import React from "react";
import SidebarChat from "../sidebarchat/SidebarChat";
import MessageContainer from "../messages/MessageContainer";

const VidChat = () => {  
  return (   
    <>
      <SidebarChat />
      <MessageContainer />
    </>
  );
};

export default VidChat;
