import React, { useEffect, useRef } from "react";
import { styled } from "@mui/system";

const Video = styled("video")(({ theme, isLocalStream, isOnCall }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "4px",
}));

const VideoPlayer = ({ stream, isLocalStream, isOnCall }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <Video
      isLocalStream={isLocalStream}
      autoPlay
      playsInline
      muted={isLocalStream}
      ref={(videoElement) => {
        if (videoElement) {
          videoElement.srcObject = stream;
        }
      }}
    />
  );
};

export default VideoPlayer;
